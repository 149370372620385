<div *ngIf="!loading && !submitting" class="col-12 banner">
    <app-slider [images]="this.banners"></app-slider>
</div>
<div *ngIf="submitting" class="card-body">
    <div class="processing">
        <span class="icon-clock"></span>
        <h3>
            Processing your enquiry
        </h3>
    </div>
</div>
<div *ngIf="!loading && !submitting" class="form">
    <div>
    <form [formGroup]="formEnquire">
        <div class="">
            <div class="">
                <div class="flex">
                    <div class="selectbox">
                        <p>Dealership</p>
                        <div class="select-radius">
                            <select formControlName="dealer">
                                <option value="-1" selected disabled hidden>Dealership</option>
                                <option *ngFor="let dealer of Dealerships" value="{{dealer.DealershipID_PK}}">
                                    {{dealer.Name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="">
            <div class="flex">
                <div class="selectbox short">
                    <p>Title</p>
                    <div class="select-radius">
                        <select formControlName="title">
                            <option value="-1" selected disabled hidden>Title</option>
                            <option *ngFor="let title of Titles" value="{{title.TitleID_PK}}">{{title.Title}}</option>
                        </select>
                    </div>
                </div>

                <div class="selectbox long"><input formControlName="name" type="text" placeholder="Name"></div>
            </div>

            <div class="selectbox "><input formControlName="surname" type="text" placeholder="Surname"></div>
            <div class="selectbox "><input formControlName="mail" type="text" placeholder="Email Address"></div>
            <div class="selectbox"><input formControlName="phone" type="text" placeholder="Mobile Number"></div>
            <div class="selectbox"><textarea rows="2" formControlName="notes" placeholder="Optional Comments"></textarea></div>
            
            <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="marketing" name="marketing"
                value="true"><p> I agree to receive digital communication from {{siteName}}</p>
            </div>
            <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="tcs"
                name="marketing" value="true">
                <p> I agree to the <a class="fake-link" (click)="tcs()">Terms and Conditions</a></p>
            </div>
            <ul *ngIf="this.formEnquire.invalid && this.failedsubmit == true" class="requirements">
                <li *ngIf="this.formEnquire.controls['dealer'].errors">Dealership is required.</li>
                <li *ngIf="this.formEnquire.controls['name'].errors">First Name is required.</li>
                <li *ngIf="this.formEnquire.controls['surname'].errors">Last Name is required.</li>
                <li *ngIf="this.formEnquire.controls['mail'].errors">Valid Email is required.</li>
                <li *ngIf="this.formEnquire.controls['phone'].errors">10+ digit Contact Number is required.</li>
                <li *ngIf="this.formEnquire.controls['tcs'].errors">Please accept the Terms and Conditions.</li>
            </ul>
            <div class="cent"><button (click)="submit()" class="btn-primary">Submit Enquiry</button></div>
        </div>
    </form>
    </div>
</div>