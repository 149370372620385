<div *ngIf="isOpen" class="alertsmodal">
    <div class="card col-12 col-md-4 offset-md-4">
        <form [formGroup]="formEnquire">
            <div class="card-header">
                Create Alert with Filter Values
                <a (click)="close()">
                    <h4><span class="icon-cross"></span></h4>
                </a>
            </div>
            <div class="card-body">
                <div class="flex">
                    <div class="col-4 selectbox">
                        <p>Title</p>
                        <div class="select-radius">
                            <select class="" formControlName="title" name="title" id="title">
                                <option value="null" selected disabled hidden>Title</option>
                                <option *ngFor="let title of Titles" value="{{title.TitleID_PK}}">{{title.Title}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-8 selectbox"><input formControlName="name" type="text" placeholder="First Name">
                    </div>
                </div>

                <div class="selectbox "><input formControlName="surname" type="text" placeholder="Surname"></div>
                <div class="selectbox "><input type="text" formControlName="email" placeholder="Email Address"></div>

                <div class="selectbox"><input type="text" formControlName="phone" placeholder="Mobile Number"></div>
                <div class="selectbox">
                    <p>Region</p>
                    <div class="select-radius">
                        <select class="" name="region" id="region">
                            <option value="null" selected disabled hidden>Region</option>
                            <option *ngFor="let region of Regions" value="{{region.RegionID_PK}}">{{region.Name}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="marketing"
                        name="marketing" value="true">
                    <p> I agree to
                        receive
                        digital
                        communication from {{site.Name}}</p>
                </div>
                <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="tcs"
                    name="marketing" value="true">
                    <p> I agree to the <a class="fake-link" (click)="tcs()">Terms and Conditions</a></p>
                </div>
                <ul *ngIf="this.formEnquire.invalid && this.failedsubmit == true" class="requirements">
                    <li *ngIf="this.formEnquire.controls['name'].errors">First Name is required.</li>
                    <li *ngIf="this.formEnquire.controls['surname'].errors">Last Name is required.</li>
                    <li *ngIf="this.formEnquire.controls['email'].errors">Valid Email is required.</li>
                    <li *ngIf="this.formEnquire.controls['phone'].errors">10+ digit Contact Number is required.</li>
                    <li *ngIf="this.formEnquire.controls['tcs'].errors">Please accept the Terms and Conditions.</li>
                </ul>
                <div class="buttons">
                    <button class="btn-primary col-4" (click)="submit()">Send Enquiry</button><button (click)="close()"
                        class="btn-tertiary col-3">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>