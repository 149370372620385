import { Component, Input, NgModule, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { ViewChild } from '@angular/core';
import { DragScrollComponent, DragScrollModule } from 'ngx-drag-scroll';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dynamicslider',
  templateUrl: './dynamicslider.component.html',
  styleUrls: ['./dynamicslider.component.less']
})
export class DynamicsliderComponent implements OnInit {

  constructor(private apiservice: GearsApiService, private siteService: SitesService) { }
  public current = 0;
  loading:boolean = true;
  FeatureImgClass: any;
  @Input() features: any = [];
  @ViewChild('badges', { read: DragScrollComponent }) ds!: DragScrollComponent;
  ngOnInit(): void {
    this.loading = false;
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }

  }
  next() {
    this.ds.moveRight();
  }

  prev() {
    this.ds.moveLeft();
  }

}

@NgModule({
  declarations: [
    DynamicsliderComponent,
  ],
  imports:[
    CommonModule,
    DragScrollModule
  ],
  exports: [
    DynamicsliderComponent
  ]
})
export class DynamicsliderModule { }