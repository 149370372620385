import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.less'],
})
export class NewsComponent implements OnInit {
  @Input() pageModule: any = '';
  banners: any[] = [];
  currentSite: any;
  NewsReadMoreDestination: any = environment.NewsRedirect;
  Articles!: any[];
  ArticleCats!: any[];
  CatNames:any[] = [];
  constructor(
    private siteService: SitesService,
    private apiService: GearsApiService
  ) {}
  ngOnInit(): void {
    this.siteService.get().then((x: any) => {
      this.currentSite = x;
      this.NewsReadMoreDestination = (this.currentSite.layout?.find((module:any) =>module.PageLayoutModuleName == 'features')).PageLayoutType
      .find((type:any) =>type.PageLayoutTypeName == 'newsurl')?.Value;
      this.apiService
        .get('Banners', {
          SiteID_FK: x.SiteID_PK,
          Module: 'Questionnaires',
          PageSize: 9999,
          PageNumber: 1,
          OrderBy: '',
        })
        .then((b: any) => {
          this.banners = b.body;
        });
      this.apiService
        .get('ArticleCategories', { SiteID_FK: this.currentSite.SiteID_PK })
        .then((x: any) => {
          this.ArticleCats = x.body;
          this.ArticleCats.forEach((x:any)=>{
            if(!this.CatNames.includes(x.Name)){
              this.CatNames.push(x.Name);
            }
          });
        });
      var NewsPageSize = this.pageModule == 'home' ? 4 : 100; 
      this.apiService
        .get('Articles', { SiteID_FK: this.currentSite.SiteID_PK,OrderBy:'DatePublished.DESC', PageSize: NewsPageSize })
        .then((x: any) => {
          this.Articles = x.body;
        });
    });
  }

  viewArticle(article: number) {
    window.location.href = '/news/' + article;
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + 'Articles/' + url;
    } else {
      return url;
    }
  }
  getCategoryArticleCount(category: string) {
    var tmpList = this.Articles?.filter(
      (o: any) => o.ArticleCategory == category && this.pageModule != 'home'
    );

    return tmpList?.length;
  }
  getCategoryArticles(category: string) {
    var tmpList = this.Articles?.filter(
      (o: any) => o.ArticleCategory == category && this.pageModule != 'home'
    );

    return tmpList.slice(0, 3);
  }

  getArticles(){
    var tmpList = this.Articles?.filter(
      (o: any) => o.ArticleCategory == null || this.pageModule == 'home'
    );
    return tmpList;
  }

  getArticleCounts(){
    var tmpList = this.Articles?.filter(
      (o: any) => o.ArticleCategory == null || this.pageModule == 'home'
    );
    return tmpList?.length ?? 0;
  }
}
