<div class="">

    <div class=" ">
        
        <form [formGroup]="formEnquire">
            
            <div *ngIf="submitloading" class="card-body">
                <div class="processing">
                    <span class="icon-clock"></span>
                    <h3>
                        Processing your enquiry
                    </h3>
                </div>
            </div>
            <div *ngIf="!submitloading" class="card-body">

                <div *ngIf="Dealerships.length > 1" class="selectbox">
                    <p>Dealership</p>
                    <div class="select-radius">
                        <select formControlName="dealer">{{this.formEnquire.controls['dealer'].value}}

                            <option value="-1" selected disabled hidden>Dealership</option>
                            <optgroup *ngFor="let region of Regions" label="{{region.Name}}">
                                <option *ngFor="let dealer of regionDealers(region.Name)"
                                    value="{{dealer.DealershipID_PK}}">
                                    {{dealer.Name}}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>

                <div class="flex">
                    <div class="col-4 selectbox">
                        <p>Title</p>
                        <div class="select-radius">
                            <select class="" formControlName="title" name="title" id="title">
                                <option value="null" selected disabled hidden>Title</option>
                                <option *ngFor="let title of Titles" value="{{title.TitleID_PK}}">{{title.Title}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-8 selectbox"><input formControlName="name" type="text" placeholder="First Name">
                    </div>
                </div>

                <div class="selectbox "><input formControlName="surname" type="text" placeholder="Surname"></div>
                <div class="selectbox "><input type="text" formControlName="email" placeholder="Email Address"></div>

                <div class="selectbox"><input type="text" formControlName="phone" placeholder="Mobile Number"></div>
                <div class="selectbox" *ngIf="showDepartment">
                    <p>Department</p>
                    <div class="select-radius">
                        <select class="" formControlName="department" name="department" id="department">
                            <option value="ContactUs" selected>General Enquiry</option>
                            <option *ngFor="let Department of departmentList" value="{{Department.Code}}">{{Department.Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="selectbox">
                    <!--<p>Region</p>
                    <select class="" name="region" id="region">
                        <option value="null" selected disabled hidden>Region</option>
                        <option *ngFor="let region of Regions" value="{{region.RegionID_PK}}">{{region.Name}}</option>
                    </select>-->
                    <textarea rows="2" formControlName="notes" placeholder="Optional Comments"></textarea>
                </div>

                <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="marketing"
                        name="marketing" value="true">
                    <p> I agree to
                        receive
                        digital
                        communication from {{site?.container?.Name}}</p>
                </div>
                <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="tcs"
                    name="marketing" value="true">
                    <p> I agree to the <a class="fake-link" (click)="tcs()">Terms and Conditions</a></p>
                </div>
                <ul *ngIf="this.formEnquire.invalid && this.failedsubmit == true" class="requirements">
                    <li *ngIf="this.formEnquire.controls['dealer'].errors">Dealership is required.</li>
                    <li *ngIf="this.formEnquire.controls['name'].errors">First Name is required.</li>
                    <li *ngIf="this.formEnquire.controls['surname'].errors">Last Name is required.</li>
                    <li *ngIf="this.formEnquire.controls['email'].errors">Valid Email is required.</li>
                    <li *ngIf="this.formEnquire.controls['phone'].errors">10+ digit Contact Number is required.</li>
                    <li *ngIf="this.formEnquire.controls['tcs'].errors">Please accept the Terms and Conditions.</li>
                </ul>
                <div class="buttons">
                    <button class="btn-primary col-4" (click)="submit()">Send Enquiry</button>
                </div>
            </div>
        </form>
    </div>
</div>

