<div class="col-12 banner">
    <app-slider [images]="banners" *ngIf="banners.length > 0"></app-slider>
</div>

<div class="service ">

    <div class="row">
        <div [ngClass]="stage == 0?'selected':''" class="page"></div>
        <div [ngClass]="stage == 1?'selected':''" class="page"></div>
        <div [ngClass]="stage == 2?'selected':''" class="page"></div>

        <div class="header">

            <h5>Request a call back or fill in the form below to book your service.</h5>

            <button (click)="callback()" class="btn-primary col-12 col-md-6"><span class="icon-phone"></span> Request a
                call back</button>
        </div>
    </div>
    <div *ngIf="stage == 0" class="body">
        <form [formGroup]="serviceForm">

            <!--<h4>Filter Dealer by Region</h4>
                    <div class="flex">
                        <div class="selectbox">
                            <p>Region</p>
                            <select (change)="regionSelected()" formControlName="region">{{this.serviceForm.controls['region'].value}}
        
                                <option value="null" selected disabled hidden>Select a Region</option>
                                <option *ngFor="let region of Regions" value="{{region}}">
                                    {{region}}
                                </option>
                            </select>
                        </div>
        
                    </div>-->
            <h4>Please select your preferred Dealer</h4>
            <div class="">
                <div class="selectbox">
                    <p>Dealership</p>
                    <div class="select-radius">
                        <select formControlName="dealer">{{this.serviceForm.controls['dealer'].value}}

                            <option value="null" selected disabled hidden>Select a Dealership</option>
                            <optgroup *ngFor="let region of Regions" label="{{region}}">
                                <option *ngFor="let dealer of regionDealers(region)" value="{{dealer.DealershipID_PK}}">
                                    {{dealer.Name}}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>

            </div>

            <button (click)="service()" class="btn-primary col-12">Book a Service Online</button>
        </form>
    </div>

    <div *ngIf="stage == 1">

        <form [formGroup]="vehicleForm" *ngIf="!isAlternativeService">
            <div class="selectbox">
                <p>Make</p>
                <div class="select-radius">
                    <select formControlName="make" (change)="makeSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Make--</option>
                        <option *ngFor="let make of this.Makes" value="{{make.Name}}">
                            {{make.Name.toUpperCase()}}</option>
                    </select>
                </div>
            </div>

            <div class="selectbox">
                <p>Series</p>
                <div class="select-radius">
                    <select *ngIf="evolvecode" formControlName="series" (change)="seriesSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Series--</option>
                        <option *ngFor="let series of this.Series" value="{{series.Series}}">{{series.Series}}
                        </option>
                    </select>
                    <select *ngIf="!evolvecode" formControlName="series" (change)="seriesSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Series--</option>
                        <option *ngFor="let series of this.Series" value="{{series.ModelCode}}">{{series.ModelName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="selectbox">
                <p>Year</p>
                <div class="select-radius">
                    <select formControlName="year" (change)="yearSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Year--</option>
                        <option *ngFor="let year of this.Years" value="{{year}}">{{year}}</option>
                    </select>
                </div>
            </div>

            <div class="selectbox">
                <p>Model</p>
                <div class="select-radius">
                    <select *ngIf="evolvecode" formControlName="model" (change)="modelSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Model--</option>
                        <option *ngFor="let model of this.ModelsOfYear" value="{{model.MMCode}}">{{model.ModelDescription}}
                        </option>
                    </select>
                    <select *ngIf="!evolvecode" formControlName="model" (change)="modelSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Model--</option>
                        <option *ngFor="let model of this.ModelsOfYear" value="{{model.VariantCode}}">{{model.VariantName}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="selectbox">
                <input formControlName="odo" required type="text" placeholder="Odometer Reading" [(ngModel)]="vehicle.Odometer">
            </div>

            <div class="selectbox">
                <p>Service Interval</p>
                <div class="select-radius">
                    <select formControlName="inter" (change)="intervalSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Service Interval--</option>
                        <option *ngFor="let inter of (this.ServiceList ? this.AlternateServiceIntervalsList : this.ServiceIntervalsList)" value="{{inter}}">{{inter}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="selectbox">
                <input formControlName="reg" required type="text" placeholder="Registration Number" [(ngModel)]="vehicle.RegistrationNumber">
            </div>
            <ul *ngIf="this.vehicleForm.invalid && this.failedsubmit == true" class="requirements">
                <li *ngIf="vehicleForm.controls['make'].errors">Make is required.</li>
                <li *ngIf="vehicleForm.controls['series'].errors">Series is required.</li>
                <li *ngIf="vehicleForm.controls['year'].errors">Year is required.</li>
                <li *ngIf="vehicleForm.controls['model'].errors">Model is required.</li>
                <li *ngIf="vehicleForm.controls['odo'].errors">Odometer Reading is required.</li>
                <li *ngIf="vehicleForm.controls['inter'].errors">Service Interval is required.</li>
                <li *ngIf="vehicleForm.controls['reg'].errors">Registration Number is required.</li>
            </ul>
            <button class="btn-primary col-12" [disabled]="vehicleForm.invalid" (click)="nextstep()">Continue ></button>
        </form>
        <form [formGroup]="alternativeVehicleForm" *ngIf="isAlternativeService">
            <div class="selectbox">
                <input formControlName="modelname" required type="text" placeholder="Model" [(ngModel)]="vehicle.ModelName"/>
            </div>
            <div class="selectbox">
                <input formControlName="odometer" required type="text" placeholder="Odometer Reading" [(ngModel)]="vehicle.Odometer">
            </div>

            <div class="selectbox">
                <p>Service Interval</p>
                <div class="select-radius">
                    <select formControlName="interval" (change)="intervalSelected($event)">
                        <option value="-1" selected disabled hidden>--Select a Service Interval--</option>
                        <option *ngFor="let inter of (this.ServiceList ? this.AlternateServiceIntervalsList : this.ServiceIntervalsList)" value="{{inter}}">{{inter}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="selectbox">
                <input formControlName="registration" required type="text" placeholder="Registration Number" [(ngModel)]="vehicle.RegistrationNumber">
            </div>
            <li *ngIf="alternativeVehicleForm.invalid && this.failedsubmit == true">Required Fields</li>
                <ul class="requirements" *ngIf="alternativeVehicleForm.invalid && this.failedsubmit == true">
                    <li *ngIf="alternativeVehicleForm.controls['modelname'].errors" class="requirement">Model is required.</li>
                    <li *ngIf="alternativeVehicleForm.controls['odometer'].errors" class="requirement">Odometer Reading is required.</li>
                    <li *ngIf="alternativeVehicleForm.controls['interval'].errors" class="requirement">Service Interval is required.</li>
                    <li *ngIf="alternativeVehicleForm.controls['registration'].errors" class="requirement">Registration Number is required.</li>
                </ul>
            <button class="btn-primary col-12" (click)="nextstep()">Continue ></button>
        </form>
    </div>

    <div *ngIf="stage == 2">
        <form [formGroup]="formEnquire">
            <div class="datepicker-center" *ngIf="!isAlternativeService">
                <label for="date" class="col-12"><strong>Select an Appointment Date</strong></label>
                <p class="dateselected" *ngIf="this.vehicle.JobDate">Date Select: {{this.confirmationDate}}</p>
                <mat-calendar class="datepicker" required *ngIf="!loading" [dateFilter]="availableBookingDateFilter" [selected]="this.dateselected" (selectedChange)="this.dateSelected($event)"></mat-calendar>
                <p *ngIf="loading">{{datepickerMessage}}</p>
            </div>

            <div *ngIf="isAlternativeService" [ngClass]="isAlternativeService ? 'datepicker-inline': ''">
                <div class="datepicker-center">
                    <label for="date" class="col-12"><strong>Select an Appointment Date</strong></label>
                    <p class="dateselected" *ngIf="this.vehicle.JobDate">Date Select: {{this.confirmationDate}}</p>
                    <mat-calendar class="datepicker" required [minDate]="minDate" [maxDate]="maxDate" [selected]="this.altdateselected[0]" (selectedChange)="this.dateSelected($event)"></mat-calendar>
                </div>
                <div class="datepicker-center">
                    <label for="date" class="col-12"><strong>Select an Alternative Appointment Date</strong></label>
                    <p class="dateselected" *ngIf="this.vehicle.AltJobDate">Date Select: {{this.altConfirmationDate}}</p>
                    <mat-calendar class="datepicker" required [minDate]="minDate" [maxDate]="maxDate" [selected]="this.altdateselected[1]" (selectedChange)="this.dateSelected($event, true)"></mat-calendar>
                </div>
            </div>
              
            <div class="flex">
                <div class="col-4 selectbox">
                    <p>Title</p>
                    <div class="select-radius">
                        <select class="" formControlName="title" name="title" id="title" (change)="titleSelected($event)">
                            <option value="null" selected disabled hidden>Title</option>
                            <option *ngFor="let title of Titles" value="{{title.TitleID_PK}}">{{title.Title}}</option>
                        </select>
                    </div>
                </div>

                <div class="col-8 selectbox"><input formControlName="name" type="text" placeholder="First Name" [(ngModel)]="customer.FirstName">
                </div>
            </div>

            <div class="selectbox "><input formControlName="surname" type="text" placeholder="Surname" [(ngModel)]="customer.Surname"></div>
            <div class="selectbox "><input type="text" formControlName="email" placeholder="Email Address" [(ngModel)]="customer.EmailAddress"></div>

            <div class="selectbox"><input type="text" formControlName="phone" placeholder="Mobile Number" [(ngModel)]="customer.ContactNo"></div>
            <div class="selectbox">
                <!--<p>Region</p>
                    <select class="" name="region" id="region">
                        <option value="null" selected disabled hidden>Region</option>
                        <option *ngFor="let region of Regions" value="{{region.RegionID_PK}}">{{region.Name}}</option>
                    </select>-->
                <textarea rows="2" formControlName="notes" placeholder="Optional Comments" [(ngModel)]="customer.Comments"></textarea>
            </div>

            <div class="col-12 market">
                <input type="checkbox" id="marketing" formControlName="marketing" name="marketing" value="true">
                <p>I agree to
                    receive
                    digital
                    communication from {{site?.container?.Name}}</p>
            </div>
            <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="tcs"
                name="marketing" value="true">
                <p> I agree to the <a class="fake-link" (click)="tcs()">Terms and Conditions</a></p>
            </div>
            <ul *ngIf="(failedsubmit && this.formEnquire.invalid) || (failedsubmit && bookingDateError) || (failedsubmit && (!this.vehicle.JobDate || this.vehicle.JobDate == null))" class="requirements">
                <li *ngIf="this.formEnquire.controls['name'].errors">First Name is required.</li>
                <li *ngIf="this.formEnquire.controls['surname'].errors">Last Name is required.</li>
                <li *ngIf="this.formEnquire.controls['email'].errors">Valid Email is required.</li>
                <li *ngIf="this.formEnquire.controls['phone'].errors">10+ digit Contact Number is required.</li>
                <li *ngIf="this.formEnquire.controls['tcs'].errors">Please accept the Terms and Conditions.</li>
                <li *ngIf="!this.vehicle.JobDate || this.vehicle.JobDate == null">Service Booking Date is required.</li>
                <li *ngIf="isAlternativeService && (!this.vehicle.AltJobDate || this.vehicle.AltJobDate == null)">Alternate Service Booking Date is required.</li>
                <li *ngIf="bookingDateError">Alternative Appointment Date can not be the same day as Appointment Date.</li>
            </ul>
            <div class="requirements" *ngIf="submitError">
                <p>Something went wrong, please try again.</p>
            </div>
            <div class="buttons">
                <button class="btn-primary col-12" *ngIf="!loading" (click)="submit()">Book Service</button>
                <button class="btn-primary col-12" *ngIf="loading" style="background-color: grey;">Please wait...</button>
            </div>
        </form>
    </div>
</div>