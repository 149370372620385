import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-pricecompare',
  templateUrl: './pricecompare.component.html',
  styleUrls: ['./pricecompare.component.less']
})
export class PricecompareComponent implements OnInit {

  constructor(private apiService: GearsApiService, private route: ActivatedRoute) { }
  banners: any[] = [];
  siteid: number = -1;
  loading: boolean = true;
  Vehicles!: any[];
  modelId: any = "-1";
  series: any = {};
  sortasc: number = 0;

  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }

  }
  back(): void {
    window.location.href = '/buy?type=new&make='+this.Vehicles[0].VehicleMakeID_FK;
  }

  pricesort(sort:number) {
    this.sortasc = sort;
    if (this.sortasc == 0) {
      this.Vehicles.sort((x, y: any) => (x.NewVehiclePrice - y.NewVehiclePrice));
      
    } else if (this.sortasc == 1) {
      this.Vehicles.sort((a: any, b: any) => {
        if (a.VehicleTransmission < b.VehicleTransmission) {
          return -1;
        }
        if (a.VehicleTransmission > b.VehicleTransmission) {
          return 1;
        }
        return 0;
      });
      
    } else if (this.sortasc == 2) {
      this.Vehicles.sort((a: any, b: any) => {
        if (a.FuelType < b.FuelType) {
          return -1;
        }
        if (a.FuelType > b.FuelType) {
          return 1;
        }
        return 0;
      });
      
    } else if (this.sortasc == 3) {
      this.Vehicles.sort((x, y: any) => (x.CapacityCC - y.CapacityCC));
      
    } 
  }
  ngOnInit(): void {

    this.modelId = this.route.snapshot.paramMap.get('id');
    this.apiService.get('VehicleModels', {
      VehicleSeriesID_FK: this.modelId
    }).then((x: any) => {
      this.Vehicles = x.body;
      this.apiService.getSingle('VehicleSeries', this.modelId
      ).then((x: any) => {
        this.series = x.body;
      });
      this.loading = false;
      this.pricesort(0);
    });

  }
}
