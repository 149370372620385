import { Component, OnInit } from '@angular/core';
import { findIndex, take } from 'rxjs';
import { ModalService } from 'src/app/modules/modalservice.service';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.less']
})
export class FavouritesComponent implements OnInit {
  Vehicles:any[] = [];
  Site:any;
  constructor(private sitesService:SitesService, private location:Location ,private apiService:GearsApiService, private modalservice:ModalService) { }

  enquire(vehicle: any) {
    var data = {
      ID:
        vehicle.SpecialID_PK ||
        vehicle.VehicleStockID_PK ||
        vehicle.VehicleModelID_PK ||
        vehicle.VehicleID,
      Name: vehicle.ModelDescription || vehicle.Caption,
      Dealer: vehicle.GroupSpecial ? null : vehicle.DealershipID_FK,
      Price: vehicle.NewVehiclePrice||vehicle.Price||vehicle.RetailPriceIncl,
      Type: vehicle.type 
    };
    this.modalservice.open('enquire', data);
  }
  urlsan(url: any) {
    return url.replaceAll('+', '').replaceAll('/', '').replaceAll('\\', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '_');
  }
  fav(id: string, type: string) {
    var favs: any[] = JSON.parse(localStorage.getItem('fav') || '[]');
    if (!favs.includes(type + '-' + id)) {
      favs.push(type + '-' + id);
    } else {
      const index = favs.indexOf(type + '-' + id);
      if (index > -1) {
        favs.splice(index, 1);
        this.Vehicles.splice(this.Vehicles.findIndex(x=>(type == 'stock'?x.VehicleStockID_PK:type=='new'?x.VehicleModelID_PK:'-1')==id),1);
      }
    }
    localStorage.setItem('fav', JSON.stringify(favs));
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }

  }
  back(): void {
    this.location.back();
  }
  ceil(val:number){
    return Math.ceil(val);
  }
  ngOnInit(): void {
    var favs = JSON.parse(localStorage.getItem('fav')||'[]');
    this.sitesService.get().then((x:any)=>{this.Site = x});
    favs.forEach((fav:any)=>{
      var splitfav:any[] = fav.split('-');
      if(splitfav[0] == 'stock'){
        this.apiService.getSingle('VehicleStock',splitfav[1]).then((x:any)=>{
          x.body.type = 'stock';
          x.body.fav = true;
          this.Vehicles.push(x.body);
        })
      }
      if(splitfav[0] == 'new'){
        this.apiService.get('VehicleModels',{VehicleModelID_PK:splitfav[1]}).then((x:any)=>{
          x.body[0].type = 'new';
          x.body[0].fav = true;
          this.Vehicles.push(x.body[0]);
        })
      }
    });
  }

  finance(price: number) {
    this.modalservice.open('finance', price);
  }

  monthlyPayment(price: any) {
    var vp = parseInt(price), //vehicle price incl vat
      // deposit as percentage. ie 10% will be passed through as 0.1
      dpamt = (vp * environment.FinanceDeposit) / 100, //deposit amount
      tm = environment.FinanceMonths, //terms month
      i = environment.FinanceInterest / 100, //interest rate
      loan = 0,
      MonPmt = 0,
      balloonAmount = (environment.FinanceBalloon / 100) * vp;
    loan = vp + environment.initfee - dpamt; //additional amounts are normally what mccarthy add on (once off fee R1140 and R57 per month)
    i /= 12;

    MonPmt = Math.ceil(
      (loan * Math.pow(1 + i, tm)) / ((Math.pow(1 + i, tm) - 1) / i) -
        balloonAmount / ((Math.pow(1 + i, tm) - 1) / i)
    );

    return MonPmt + environment.adminfee;
  }

}
