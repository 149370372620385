<div *ngIf="!loading && features.length > 0" class="container-fluid featureList">
    <div class="row flex-row flex-nowrap">
        <drag-scroll [snap-offset]="0" #badges [snap-disabled]="true">
            <div class="item" drag-scroll-item *ngFor="let f of features; index as i">
            <div class="card" >
                <div [innerHTML]="f.HTMLValue"></div>
                <!-- <div class="card-img-top image"><a href="{{b.Link}}"><img loading="lazy" class="d-block w-10" [ngClass]="FeatureImgClass"
                            src="{{imgadd(b.ImageURL)}}" alt="{{b.ImageURL}}"></a></div>
                <div class="card-body">
                    <a *ngFor="let but of b.Buttons" href="{{but.URL}}"><button class="btn-primary">{{but.Label}}</button></a>
                    
                </div> -->
            </div>
            </div>
        </drag-scroll>

        
    </div>
    <button *ngIf="features.length > 1" aria-label="Prev" class="prev arrow" type="button" (click)="prev()">
        <span class="icon-arrowleft"></span>
    </button>
    <button *ngIf="features.length > 1" aria-label="Next" class="next arrow" type="button" (click)="next()">
        <span class="icon-arrowright"></span>
    </button>
</div>