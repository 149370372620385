import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { SitesService } from 'src/app/services/sites.service';
import { ModalService } from '../../modules/modalservice.service';
import { Console } from 'console';
import { generate } from 'rxjs';

@Component({
  selector: 'app-fleetcontactus',
  templateUrl: './fleetcontactus.component.html',
  styleUrls: ['./fleetcontactus.component.less']
})
export class FleetContactUsComponent implements OnInit {
  loading: boolean = false;
  banners: any[] = [];
  Titles: any = [];
  failedsubmit: boolean = false;
  submitting: any = false;
  Regions: any = [];
  Dealerships: any = [];
  formEnquire!: FormGroup;
  siteid: any;
  siteName: any;
  currentSite: any;
  enquiryType: any;
  constructor(
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private siteService: SitesService,
    @Inject(DOCUMENT) private _document: Document,
    private renderer: Renderer2,
    private apiService: GearsApiService
  ) {
    
  }

  ngOnInit(): void {
    this.loading = true;
    this.formEnquire = this.formBuilder.group({
      dealer: [-1, Validators.min(0)],
      title: [-1],
      region: [-1],
      name: [null, Validators.required],
      surname: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]],
      phone: [null, [Validators.required, Validators.pattern(/^(?:[\+][0-9])?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/)]],
      marketing: [false],
      notes: [null],
      tcs: [false, Validators.requiredTrue]
    });

    this.siteService.get().then((x: any) => {
      this.siteid = x.SiteID_PK;
      this.siteName = x.container.Name;
      this.currentSite = x;

      this.apiService.get('Banners', {
        SiteID_FK: this.siteid,
        Module: 'FleetContactUs',
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: ''
      }).then((x: any) => {

        this.banners = x.body;
        this.loading = false;
      });

      this.apiService.get('Dealerships', {
        PageSize: 9999,
        PageNumber: 1,
        OrderBy: '',
        SiteID_FK: this.siteid
      }, true).then((x: any) => {
        this.Dealerships = x.body;
        if (this.Dealerships.length == 1) {
          this.formEnquire.controls['dealer'].setValue(
            this.Dealerships[0].DealershipID_PK
          );
        }
      });

    });
    this.apiService.get('Regions', {
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.Regions = x.body;
    });

    this.apiService.get('Titles', {
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: ''
    }).then((x: any) => {
      this.Titles = x.body;
    });

    this.apiService.get('EnquiryTypes',{
      PageSize: 9999,
      PageNumber: 1,
      OrderBy: '',
      Active: 1,
      Deleted: 0
    },true).then((x:any) => {
      this.enquiryType = x.body;
    })
  }

  submit() {
    if (this.formEnquire.invalid) {
      this.failedsubmit = true;
    }
    else {
      var GAData = {
        event: 'lead_submit',
        lead_type: 'automotive',
        lead_source: 'website',
        lead_first_name: this.formEnquire.controls['name'].value,
        lead_last_name: this.formEnquire.controls['surname'].value,
        lead_email: this.formEnquire.controls['mail'].value,
        lead_phone: this.formEnquire.controls['phone'].value,
        lead_comments: this.formEnquire.controls['notes'].value || '',
      };

      try {
        window.dataLayer.push(GAData);
        sessionStorage.setItem('GAData', JSON.stringify(GAData));
      } catch (e) {}

      var GUID = (this.generateGUID())+'-T'+Date.now();
      let script = this.renderer.createElement('script');
      script.text = "fbq('track', 'Lead', {currency: 'ZAR', value: 'null'}, {eventID:\""+GUID+"\" });";

      this.renderer.appendChild(this._document.body, script);
      
      
      this.submitting = true;

      var post = {
        Active: 1,
        ContactNo: this.formEnquire.controls['phone'].value,
        DealershipID_FK: this.formEnquire.controls['dealer'].value,
        Deleted: 0,
        Email: this.formEnquire.controls['mail'].value,
        Marketing: this.formEnquire.controls['marketing'].value,
        Name: this.formEnquire.controls['name'].value,
        Notes: this.formEnquire.controls['notes'].value || '',
        SiteID_FK: this.currentSite.SiteID_PK,
        TitleID_FK: this.formEnquire.controls['title'].value,
        Surname: this.formEnquire.controls['surname'].value,
        UtmCampaign: null,
        VehicleModelID_FK: null,
        SpecialID_FK: null,
        VehicleStockID_FK: null,
        EnquiryTypeID_FK: this.enquiryType.find(
          (x: any) => x.Code == 'Fleet'
        ).EnquiryTypeID_PK,
        LinkURL: window.location.href,
        FacebookFBP: this.getCookie('_fbp'),
        FacebookFBC: this.getCookie('_fbc'),
        FacebookFBE: GUID,
      };

      this.apiService.post('Enquiries', post).then((x: any) => {
        if (x.EnquiryID_PK !== null) {
          window.location.href = '/fleetcontactus/thankyou';
        }
      });
    }
  }

  getCookie(name: any) {
    var value = `; ${document.cookie}`;
    var parts!: any;
    parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    else return null;
  }

  generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c){
      var r = (Math.random() * 16)| 0,
      v = c === 'x' ? r: (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  tcs() {
    this.modalService.open('tcs');
  }

}
