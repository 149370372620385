<div class="row head">
    <h5 class="col-2" (click)="back()"><span class="icon-back"></span> Back</h5>
    <h4 class="col-8">Favourites</h4>
</div>
<div class="row content">
    <div class="col-12 col-xl-3 col-lg-4 col-md-6 item" *ngFor="let v of Vehicles">
        <div *ngIf="v.type == 'new'" class="card">
            <a class="image card-img-top" href="buy/new/{{v.VehicleModelID_PK}}/{{urlsan(v.ModelDescription)}}"><img loading="lazy"
                    *ngIf="v.Images !== null && v.Images[0]" src="{{imgadd(v.Images[0])}}" alt="">
                <img loading="lazy" *ngIf="v.Images == null || !v.Images[0]" src="{{Site.vehicleImages.Value}}" alt=""></a>
                <div style="
                    border-top: lightgray solid 1px;
                    margin: 0 15px;
                "></div>
            <div class="card-body">
                <div class="bodyblock">
                    <div class="row name">
                        <h6>{{v.ModelDescription}}</h6><button class="selected"
                            (click)="fav(v.VehicleModelID_PK,'new')"><span
                                class="icon-favourite"></span></button>
                    </div>
                    <div class="bodyrow">
                        <div class="row pricerow">
                            <h5 class="col-6">R{{v.NewVehiclePrice||v.Price | number:'1.0-0'}}</h5>
                            <a class="col-6" (click)="finance(v.NewVehiclePrice||v.Price)">
                                <p>From R{{monthlyPayment(v.NewVehiclePrice||v.Price)| number:'1.0-0'}} pm
                                </p>
                            </a>
                        </div>
                        <p class="details">{{v.VehicleTransmission||''}} | {{v.FuelType}} | New </p>
                        <p>Any {{Site?.container?.Name}} Dealer </p>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-xs-10 col-xs-offset-1">
                        <a href="buy/new/{{v.VehicleModelID_PK}}/{{urlsan(v.Name)}}"><button class="col-4 btn-tertiary"><span
                                    class="icon-info"></span> Info</button></a>
                        <button (click)="enquire(v)" class="col-4 btn-primary">Enquire</button>
                        <a *ngIf="Site.dealerSite" class="call" href="tel:{{Site.dealership.PhoneNumber}}"><span
                            class="icon-phone"></span></a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="v.type == 'stock'" class="card">
            <a class="image card-img-top" href="buy/stock/{{v.VehicleStockID_PK}}/{{urlsan(v.WebFriendlyName)}}"><img loading="lazy"
                    *ngIf="v.Images !== null && v.Images[0]" src="{{imgadd(v.Images[0])}}" alt="">
                <img loading="lazy" *ngIf="v.Images == null || !v.Images[0]" src="{{Site.vehicleImages.Value}}" alt=""></a>
                <div style="
                    border-top: lightgray solid 1px;
                    margin: 0 15px;
                "></div>
            <div class="card-body">
                <div class="bodyblock">
                    <div class="row name">
                        <h6>{{v.ModelYear}} {{v.WebFriendlyName}}</h6><button class="selected"
                            (click)="fav(v.VehicleStockID_PK,'stock')"><span
                                class="icon-favourite"></span></button>
                    </div>
                    <div class="row pricerow">
                        <h5 class="col-6">R{{v.RetailPriceIncl||v.Price | number:'1.0-0'}}</h5>
                        <a class="col-6" (click)="finance(v.RetailPriceIncl||v.Price)">
                            <p>From R{{monthlyPayment(v.RetailPriceIncl||v.Price)| number:'1.0-0'}} pm</p>
                        </a>
                    </div>
                    <p class="details">{{v.OdoMeterReading | number:'1.0-0'}}kms | {{v.VehicleTransmission}} | {{v.VehicleFuelType}} | {{v.VehicleStockType||v.Type}} </p>
                    <p>{{v.DealershipName}} </p>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-xs-10 col-xs-offset-1">
                        <a href="buy/stock/{{v.VehicleStockID_PK}}/{{urlsan(v.WebFriendlyName)}}"><button class="col-4 btn-tertiary"><span
                                    class="icon-info"></span> Info</button></a>
                        <button (click)="enquire(v)" class="col-4 btn-primary">Enquire</button>
                        <a class="call" href="tel:{{v.DealershipPhoneNumber}}"><span
                                class="icon-phone"></span></a>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="v.type == 'special'" class="card">
            <a class="image card-img-top" href="buy/special/{{v.SpecialID_PK}}/{{urlsan(v.Caption)}}"><img loading="lazy" *ngIf="v.Image !== null"
                    src="{{v.Image}}" alt="">
                <img loading="lazy" *ngIf="v.Image == null" src="{{Site.vehicleImages.Value}}" alt=""></a>
                <div style="
                    border-top: lightgray solid 1px;
                    margin: 0 15px;
                "></div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-xs-10 col-xs-offset-1">
                        <a href="buy/special/{{v.SpecialID_PK}}/{{urlsan(v.Caption)}}"><button class="col-4 btn-tertiary"><span
                                    class="icon-info"></span> Info</button></a>
                        <button (click)="enquire(v)" class="col-4 btn-primary">Enquire</button>
                        <a *ngIf="v.DealershipPhoneNumber" class="call"
                            href="tel:{{v.DealershipPhoneNumber}}"><span class="icon-phone"></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>