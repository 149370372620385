import { Component, OnInit } from '@angular/core';
import { GearsApiService } from 'src/app/services/gearsapi.service';
import { ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { SitesService } from 'src/app/services/sites.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vehicleseries',
  templateUrl: './vehicleseries.component.html',
  styleUrls: ['./vehicleseries.component.less']
})
export class VehicleseriesComponent implements OnInit {

  constructor(private apiservice: GearsApiService, private siteService: SitesService) { }
  public banners: Array<any> = [];
  public current = 0;
  loading:boolean = true;
  FeatureImgClass: any;
  @ViewChild('badges', { read: DragScrollComponent }) ds!: DragScrollComponent;
  ngOnInit(): void {
    this.loading = true;
    this.siteService.get().then((x: any) => {
      if(x.layoutFeatures?.includes('slidebannerlarge')) {
        this.FeatureImgClass = 'imglg';
      }
      else{
        this.FeatureImgClass = 'imgmd';
      }
      this.apiservice.get('SlideBanners', { SiteID_FK: x.SiteID_PK, OrderBy:'Order.ASC' }).then((s: any) => {
        this.banners = s.body;
        this.banners.forEach((x:any)=>{
          x.Buttons = JSON.parse(x.Buttons);
        });
        this.loading = false;
      });
    });
  }
  imgadd(url: string) {
    if (!url.includes('http')) {
      return environment.storageurl + url;
    }
    else {
      return url;
    }

  }
  next() {
    this.ds.moveRight();
  }

  prev() {
    this.ds.moveLeft();
  }

}
