<div *ngIf="Site && Site.dealerSite" class="bottom-nav row">
    <div *ngIf="Site.dealerSite" class="col-4 bottom-nav-item">
        <H4>CONTACT</H4>
        <div *ngIf="this.Site" class="col-12">
            <a href="tel:{{Site.ContactPhone}}">
                <p class="phone" *ngIf="Site.ContactPhone"><span class="icon-phone"></span>{{Site.ContactPhone}}</p>
            </a>
            <a href="dealership/{{this.Site.dealership.DealershipID_PK}}">
                <p class="phone"><span class="icon-contact"></span>Contact Us</p>
            </a>
            <a href="/whyservice" *ngIf="checkWhyService(footerVehicleOption)">
                <p class="phone"><span class="icon-specialsservice"></span>Why Service with {{Site?.container?.Name}}</p>
            </a>

        </div>

    </div>
    <div class="col-xs-12 vehicles bottom-nav-item">
        <h4>VEHICLES</h4>
        <ng-container *ngFor="let footer of footerVehicleOption">
            <a *ngIf="footer.name.toLowerCase() != 'newtab'" href="{{footer.url}}"><p>{{footer.text}}</p></a>
            <a *ngIf="footer.name.toLowerCase() == 'newtab'" href="{{footer.url}}" target="_blank"><p>{{footer.text}}</p></a>
        </ng-container>
    </div>
    <div *ngIf="Site.dealerSite" class="col-xs-12 social bottom-nav-item">
        <div class="row">
            <H4 *ngIf="((Site.dealerSite) && (this.Site.FacebookURL !== null || this.Site.InstagramURL !== null || this.Site.TwitterURL !== null || this.Site.YoutubeURL !== null || this.Site.LinkedinURL !== null || this.Site.WhatsappURL !== null))">SOCIAL</H4>
            <div *ngIf="this.Site" class="col-12">
                <a aria-label="Site Facebook Page" *ngIf="this.Site.FacebookURL !== null" href="{{this.Site.FacebookURL}}" target="_blank"><span
                        class="icon-facebook"></span></a>
                <a aria-label="Site Instagram Page" *ngIf="this.Site.InstagramURL !== null" href="{{this.Site.InstagramURL}}" target="_blank"><span
                        class="icon-instagram"></span></a>
                <a aria-label="Site LinkedIn Page" *ngIf="this.Site.LinkedinURL !== null" href="{{this.Site.LinkedinURL}}" target="_blank"><span
                    class="icon-linkedin"></span></a>
                <a aria-label="Site Twitter Page" *ngIf="this.Site.TwitterURL !== null" href="{{this.Site.TwitterURL}}" target="_blank"><span
                        class="icon-twitter"></span></a>
                <a aria-label="Site Whatsapp Channel" *ngIf="this.Site.WhatsappURL !== null" href="{{this.Site.WhatsappURL}}" target="_blank"><span
                        class="icon-whatsapp"></span></a>
                <a aria-label="Site Youtube Channel" *ngIf="this.Site.YoutubeURL !== null" href="{{this.Site.YoutubeURL}}" target="_blank"><span
                        class="icon-youtube"></span></a>
            </div>
            <!--<div *ngIf="Site.bottomnavBanner.Value" class="col-md-6 col-12">
                <img loading="lazy" class="bidlogo" src="{{Site.bottomnavBanner.Value}}" alt="">

            </div>-->
            <div *ngIf="this.Site.noClubBanner" class="col-md-6 col-12">
                <img loading="lazy" class="bidlogo" src="{{Site.noClubBanner}}" alt="">
            </div>
            <div class="col-12" *ngIf="this.Site.bottomnavBanner.Value"></div>
            <div class="col-12" *ngIf="!this.Site.noClubBanner">
                <a aria-label="Bidvest Club McCarthy" href="https://www.mccarthy.co.za/club"><img loading="lazy" class="bidlogo" src="assets/BidvestClubLogo.webp" alt=""></a>

            </div>
        </div>
    </div>
</div>



<div *ngIf="Site && !Site.dealerSite" class="bottom-nav row">
    <div *ngIf="!Site.dealerSite" class="col-xs-12 social bottom-nav-item">
        <H4 *ngIf="((!Site.dealerSite) && (this.Site.FacebookURL !== null || this.Site.InstagramURL !== null || this.Site.TwitterURL !== null || this.Site.YoutubeURL !== null || this.Site.LinkedinURL !== null || this.Site.WhatsappURL !== null))">SOCIAL</H4>
        <div *ngIf="this.Site" class="col-12">
            <a aria-label="Site Facebook Page" *ngIf="this.Site.FacebookURL !== null" href="{{this.Site.FacebookURL}}" target="_blank"><span
                    class="icon-facebook"></span></a>
            <a aria-label="Site Instagram Page" *ngIf="this.Site.InstagramURL !== null" href="{{this.Site.InstagramURL}}" target="_blank"><span
                    class="icon-instagram"></span></a>
            <a aria-label="Site LinkedIn Page" *ngIf="this.Site.LinkedinURL !== null" href="{{this.Site.LinkedinURL}}" target="_blank"><span
                class="icon-linkedin"></span></a>
            <a aria-label="Site Twitter Page" *ngIf="this.Site.TwitterURL !== null" href="{{this.Site.TwitterURL}}" target="_blank"><span
                    class="icon-twitter"></span></a>
            <a aria-label="Site Whatsapp Channel" *ngIf="this.Site.WhatsappURL !== null" href="{{this.Site.WhatsappURL}}" target="_blank"><span
                    class="icon-whatsapp"></span></a>
            <a aria-label="Site Youtube Channel" *ngIf="this.Site.YoutubeURL !== null" href="{{this.Site.YoutubeURL}}" target="_blank"><span
                    class="icon-youtube"></span></a>
        </div>
        <!--<div *ngIf="Site.bottomnavBanner.Value" class="col-md-6 col-12">
            <img loading="lazy" class="bidlogo" src="{{Site.bottomnavBanner.Value}}" alt="">

        </div>-->
        <div *ngIf="this.Site?.noClubBanner" class="col-md-6 col-12">
            <img loading="lazy" class="bidlogo" src="{{Site.noClubBanner}}" alt="">
        </div>
        <div *ngIf="this.Site">
            <a *ngIf="!this.Site.noClubBanner" aria-label="Club McCarthy Page" href="https://www.mccarthy.co.za/club"><img loading="lazy" class="bidlogo" src="assets/BidvestClubLogo.webp" alt=""></a>
            <a href="tel:{{Site.ContactPhone}}">
                <p class="phone" *ngIf="Site.ContactPhone"><span class="icon-phone"></span>{{Site.ContactPhone}}</p>
            </a>
        </div>
    </div>

    <div class="col-xs-12 vehicles bottom-nav-item">
        <h4>VEHICLES</h4>
        <ng-container *ngFor="let footer of footerVehicleOption">
            <a *ngIf="footer.name.toLowerCase() != 'newtab'" href="{{footer.url}}"><p>{{footer.text}}</p></a>
            <a *ngIf="footer.name.toLowerCase() == 'newtab'" href="{{footer.url}}" target="_blank"><p>{{footer.text}}</p></a>
        </ng-container>
     

    </div>
    <div *ngIf="!Site.dealerSite" class="col-xs-12 dealers bottom-nav-item">
        <h4>DEALERSHIPS</h4>
        <div id="accordionDealers" *ngIf="!loading" class="accordion regions d-block d-md-flex mobileflow" [ngClass]="this.flowdown">
            <div class="dealerregion" *ngFor="let region of Regions">
                <div class="region " id="heading{{region}}">
                    <h2 class="accordion-header" id="heading{{region}}">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse' + region.replace(' ', '')" aria-expanded="false"
                            [attr.aria-controls]="'collapse'+region.replace(' ', '')">
                            {{region}} &#8964;
                        </button>
                    </h2>
                    <div id="collapse{{region.replace(' ', '')}}" class="accordion-collapse collapse"
                        data-bs-parent="#accordionDealers">
                        <a href="/dealership/{{dealer.DealershipID_PK}}" *ngFor="let dealer of regionDealers(region)">
                            <p>{{dealer.Name}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>