<div *ngIf="!loading" class="col-12 banner">
    <app-slider [images]="this.banners"></app-slider>
</div>
<div class="parts row">
    <form [formGroup]="formParts">


        <div class="">
            <div *ngIf="Dealerships.length > 1" class="flex">
                <div class="selectbox">
                    <p>Dealership</p>
                    <div class="select-radius">
                        <select formControlName="dealer">{{this.formParts.controls['dealer'].value}}

                            <option value="-1" selected disabled hidden>Dealership</option>
                            <optgroup *ngFor="let region of Regions" label="{{region.Name}}">
                                <option *ngFor="let dealer of regionDealers(region.Name)"
                                    value="{{dealer.DealershipID_PK}}">
                                    {{dealer.Name}}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>

            <div class="selectbox ">
                <input formControlName="partdesc" placeholder="Part Description" type="text">
            </div>
            <div class="selectbox ">
                <input formControlName="modeldesc" placeholder="Vehicle Model" type="text">
            </div>


        </div>

        <h4>
            Your Information
        </h4>
        <div class="">
            <div class="flex">
                <div class="selectbox short">
                    <p>Title</p>
                    <div class="select-radius">
                        <select formControlName="title">
                            <option value="-1" selected disabled hidden>Title</option>
                            <option *ngFor="let title of Titles" value="{{title.TitleID_PK}}">{{title.Title}}</option>
                        </select>
                    </div>
                </div>

                <div class="selectbox long">
                    <input formControlName="name" placeholder="First Name" type="text">
                </div>
            </div>

            <div class="selectbox ">
                <input formControlName="surname" placeholder="Surname" type="text">
            </div>
            <div class="selectbox ">
                <input formControlName="mail" placeholder="Email Address" type="text">
            </div>
            <div class="selectbox">
                <input formControlName="phone" placeholder="Mobile Number" type="text">

            </div>
            <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="marketing"
                    name="marketing" value="true">
                <p> I agree to
                    receive
                    digital
                    communication from {{Site?.container?.Name}}</p>
            </div>
            <div class="col-12 market"><input type="checkbox" id="marketing" formControlName="tcs"
                name="marketing" value="true">
                <p> I agree to the <a class="fake-link" (click)="tcs()">Terms and Conditions</a></p>
            </div>
            <ul *ngIf="this.formParts.invalid && this.failedsubmit == true" class="requirements">
                <li *ngIf="this.formParts.controls['dealer'].errors">Dealership is required.</li>
                <li *ngIf="this.formParts.controls['partdesc'].errors">Part Description is required.</li>
                <li *ngIf="this.formParts.controls['modeldesc'].errors">Vehicle Model is required.</li>
                <li *ngIf="this.formParts.controls['name'].errors">First Name is required.</li>
                <li *ngIf="this.formParts.controls['surname'].errors">Last Name is required.</li>
                <li *ngIf="this.formParts.controls['mail'].errors">Valid Email is required.</li>
                <li *ngIf="this.formParts.controls['phone'].errors">10+ digit Contact Number is required.</li>
                <li *ngIf="this.formParts.controls['tcs'].errors">Please accept the Terms and Conditions.</li>
            </ul>
            <button (click)="submit()" [disabled]="submitting" class="btn-primary col-4">Send Enquiry</button>
        </div>

    </form>
</div>