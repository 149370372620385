<div *ngIf="!labelBanner" id="sliderCarouselControls{{id}}" class="carousel slide carousel-fade" [ngClass]="carouselStyling"  data-bs-ride="carousel" data-bs-pause="false">
    <div *ngIf="autoScroll" class="carousel-inner">
        <div class="carousel-indicators">
            <button type="button" class="carousel-button" attr.data-bs-target="sliderCarouselControls{{id}}" attr.data-bs-slide-to="{{e}}"
                *ngFor="let image of images; let e = index" [ngClass]="e == activeIndex?'active':''">
                {{image.OverlayText}}{{image.PreviewText}}
            </button>
        </div>
        <div class="carousel-item" [ngClass]="i == activeIndex?'active':''" *ngFor="let image of images; let i = index" data-bs-interval="5000">       
            <a *ngIf="image.LinkURL && image.NewTab" href="{{image.LinkURL}}"  target="_blank"><img loading="lazy" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide"></a>
            <a *ngIf="image.LinkURL && !image.NewTab" href="{{image.LinkURL}}"><img loading="lazy" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide"></a>
            <img loading="lazy" *ngIf="!image.LinkURL" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide">
        </div>
    </div>
    <div *ngIf="!autoScroll" class="carousel-inner">
        
        <div class="carousel-item" [ngClass]="i == activeIndex?'active':''" *ngFor="let image of images;let i = index" data-bs-interval="99999999999999999">
            <a *ngIf="image.LinkURL && image.NewTab" href="{{image.LinkURL}}"  target="_blank"><img loading="lazy" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide"></a>
            <a *ngIf="image.LinkURL && !image.NewTab" href="{{image.LinkURL}}"><img loading="lazy" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide"></a>
            <img loading="lazy" *ngIf="!image.LinkURL && image.ImageURL" class="d-block" src="{{imgadd(image.ImageURL||image)}}" alt="First slide" >
            <img loading="lazy" *ngIf="!image.LinkURL && !image.ImageURL" class="d-block" src="{{imgadd(image)}}" alt="First slide" >
        </div>
    </div>
    <ng-container *ngIf="!carouselStyling?.includes('sideBySide')">
        <a aria-label="Banner Back" class="carousel-control-prev" *ngIf="!singleImage" href="#sliderCarouselControls{{id}}" type="button" data-bs-slide="prev" id="CarouselControlPrev{{id}}">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </a>
        <a aria-label="Banner Forward" class="carousel-control-next" *ngIf="!singleImage" href="#sliderCarouselControls{{id}}" type="button" data-bs-slide="next" id="CarouselControlNext{{id}}">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
        </a>
    </ng-container>
    <ng-container *ngIf="carouselStyling?.includes('sideBySide')">
        <a aria-label="Banner Back" class="prev arrow" *ngIf="!singleImage" href="#sliderCarouselControls{{id}}" type="button" data-bs-slide="prev" id="CarouselControlPrev{{id}}">
            <span class="icon-arrowleft" aria-hidden="true"></span>
        </a>
        <a aria-label="Banner Forward" class="next arrow" *ngIf="!singleImage" href="#sliderCarouselControls{{id}}" type="button" data-bs-slide="next" id="CarouselControlNext{{id}}">
            <span class="icon-arrowright" aria-hidden="true"></span>
        </a>
    </ng-container>
</div>


<div *ngIf="labelBanner" id="sliderCarouselControls{{id}}" class="carousel slide" [ngClass]="carouselStyling">
    <div *ngIf="autoScroll" class="carousel-inner">
        <div class="carousel-indicators">
            <button id="carouselIndicators{{id}}" type="button" class="carousel-button" attr.data-bs-target="sliderCarouselControls{{id}}" attr.data-bs-slide-to={{e}} (click)="indicatorButton(e)"
                *ngFor="let image of images; let e = index" [ngClass]="e == activeIndex?'active':'mobile-indicator'">
                <p><span class="colourSecondary">{{image.OverlayText}}</span><span class="colourPrimary">{{image.PreviewText}}</span></p>
            </button>
        </div>
        <div class="carousel-item mobile-carousel" id="carouselItem{{id}}" [ngClass]="i == activeIndex?'active':''" *ngFor="let image of images; let i = index" data-bs-interval="5000">       
            <a *ngIf="image.LinkURL && image.NewTab" href="{{image.LinkURL}}"  target="_blank"><img loading="eager" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide"></a>
            <a *ngIf="image.LinkURL && !image.NewTab" href="{{image.LinkURL}}"><img loading="eager" class="d-block" src="{{imgadd(image.ImageURL)}}" alt="First slide"></a>
            <img loading="eager" *ngIf="!image.LinkURL && image.ImageURL" class="d-block" src="{{imgadd(image.ImageURL||image)}}" alt="First slide" >
            <img loading="eager" *ngIf="!image.LinkURL && !image.ImageURL" class="d-block" src="{{imgadd(image)}}" alt="First slide" >
            <!-- <a *ngIf="image.LinkURL" href="{{image.LinkURL}}" class="infoButton">Info</a> -->
        </div>
    </div>
    <a aria-label="Banner Back" class="carousel-control-prev" *ngIf="!singleImage" type="button" (click)="prev()" id="CarouselControlPrev{{id}}">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a aria-label="Banner Forward" class="carousel-control-next" *ngIf="!singleImage" type="button" (click)="next()" id="CarouselControlNext{{id}}">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
</div>